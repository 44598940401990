import React, {useEffect} from 'react';
import styled from '@emotion/styled';
import Image from 'gatsby-image';
import { graphql } from "gatsby";
import Sticky from 'react-stickynode';
import ReactMarkdown from 'react-markdown';

import Layout from './layout';
import SEO from "../components/seo";

const Contenido = styled.div`
    max-width: 1200px;
    margin: 0 auto;
    width: 95%;

    @media (min-width: 768px){
        display: grid;
        grid-template-columns: 2fr 1fr;
        column-gap: 5rem;
    }
    .textoContenido{
        text-align: justify;
        margin-top: 2rem;
    }

    
`;
const Sidebar = styled.aside`
    .categoria{
        font-size: 2rem;
        color: #777777;
    }
    .autor{
        margin-top: 1rem;
        border-radius: 2rem;
        background-color: #eeeeee;
        padding: 3rem;
        color: #777777;

        p{
            margin: 0;
        }
    }
`;

export const query = graphql`
    query($id: String!){
        allStrapiArticulos(filter: {id: {eq: $id}}){
            nodes{
                titulo
                contenido
                updatedAt
                published_at
                autor{
                    nombre
                }
                categoria{
                    nombre
                }
                imagen{
                    sharp: childImageSharp{
                        fluid(maxWidth: 1200){
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            }
        }
    }
`

const Articulos = ({data: {allStrapiArticulos: {nodes}}}) => {

    const { titulo, contenido, published_at, updatedAt, autor, categoria, imagen} = nodes[0];

    const fechaPub = published_at.slice(0,10);
    const fechaAct = updatedAt.slice(0,10);

    //Comentarios con graphcomment
    useEffect(() => {
        window.gc_params = {
            graphcomment_id: 'orlandomelo',
            fixed_header_height: 0,
        };
    
        (function() {
            var gc = document.createElement('script'); gc.type = 'text/javascript'; gc.async = true;
            gc.src = 'https://graphcomment.com/js/integration.js?' + Date.now();
            (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(gc);
        })();
    }, [])

    return (  
        <Layout>
            <SEO title={titulo} />
            <h1>{titulo}</h1>
            <Contenido>
                <main>
                    <Image 
                        fluid={imagen.sharp.fluid}
                    />
                    <ReactMarkdown
                        className="textoContenido"
                    >{contenido}</ReactMarkdown>
                    <div id="graphcomment"></div>
                </main>
                <Sticky bottomBoundary='.textoContenido'>
                    <Sidebar>
                        <div className="autor">
                            <h3>Categoría: </h3>
                            <p>{categoria.nombre}</p>
                        </div>                    
                        <div className="autor">
                            <h3>Autor:</h3>
                            <p>{autor.nombre}</p>
                            <p>Fecha de publicación: </p>
                            <p>{fechaPub}</p>
                            <p>Actualizado en: </p>
                            <p>{fechaAct}</p>
                        </div>
                        <div className="autor">
                            <h3>Tema:</h3>
                            <p>Otra Información imporante para agregar</p>
                        </div>
                    </Sidebar>
                </Sticky>
            </Contenido>
        </Layout>
    );
}
 
export default Articulos;